import axios from 'axios';
import { createStore } from 'vuex';
// import mockProjects from "@/data/projects.json";

const store = createStore ({
  state: {
    toastQueue: [],
    isMobile: false,
    projects: [],
    hasAdminAccess: false
  },
  mutations: {
    setNewToast(state, options){
      state.toastQueue.push(options);
    },
    setRemoveToast(state, id) {
      let currentMessageIndex = state.toastQueue.findIndex(t => t.id == id);
      if(currentMessageIndex >= 0)
        state.toastQueue.splice(currentMessageIndex, 1);
    },
    setIsMobile(state, value) {
      state.isMobile = value;
    },
    setProjects(state, value) {
      state.projects = value;
    },
    setSaveProject(state, value) {
      let foundProjectIndex = state.projects?.findIndex(a => a.sop_project_id == value.sop_project_id);
      if(foundProjectIndex >= 0){
        state.projects[foundProjectIndex] = value;
      } else {
        state.projects.push(value);
      }
    },
    setDeleteProject(state, value) {
      let foundProjectIndex = state.projects?.findIndex(a => a.sop_project_id == value);
      if(foundProjectIndex >= 0){
        state.projects.splice(foundProjectIndex, 1);
      }
    },
    setHasAdminAccess(state, value) {
      state.hasAdminAccess = value;
    }
  },
  actions: {
    addToastMessage({commit}, options){
      commit('setNewToast', options)
    },
    removeToastMessage({commit}, id){
      commit('setRemoveToast', id);
    },
    async getProjects({commit}) {
      let response = await axios.post('/getProjects', null, {params: { org: 'studio' } });
      if(response.data && response.status == 200){
        commit('setProjects', response.data);
      }
      //Mock results
      // commit('setProjects', mockProjects?.results);
    },
    async saveProject({commit}, project) {
      //add our org
      if(!project.org)
        project.org = 'studio';

      let payload = {params: { project: project }};
      let response = await axios.post('/saveProject', null, payload);
      if(response?.data?.error == false && response?.data?.project){
        commit('setSaveProject', response?.data?.project);
      }
    },
    async deleteProject({commit}, projectId) {
      let payload = {params: { projectId: projectId }};
      let response = await axios.post('/deleteProject', null, payload);
      if(response?.data?.error == false){
        commit('setDeleteProject', projectId);
        return true;
      } else {
        return false;
      }
    },
    async submitPassword({commit}, password) {
      let payload = {params: { password: password }};
      let response = await axios.post('/adminAccess', null, payload);
      if(response.data && response.status == 200){
        if(response.data.adminAccessGranted){
          commit('setHasAdminAccess', response.data.adminAccessGranted);
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    async getSecureS3Url() {
      let response = await axios.get('/getSecureS3Url', null);
      if(response?.data?.url && response?.data?.error == false){
        return response.data.url;
      }
    },
    async deleteS3Media({commit}, mediaId) {
      let payload = {params: {mediaId: mediaId}};
      let response = await axios.get('/deleteS3Media', payload);
      if(response?.data && response?.data?.error == false){
        return response.data.deleted;
      }
    }
  }
});

export default store;