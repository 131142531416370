<template>
  <div class="team-member full-width" :class="{'no-img' : !memberData.imgSrc}">
    <img v-if="memberData.imgSrc" class="member-img" :class="customClasses" :src="memberData.imgSrc" :alt="'image of ' + memberData.name" />
    <div class="member-details">
      <div class="member-name row items-center text-h2 uppercase">
        <div class="row" :class="oppositeClass">{{ memberData.name }}</div>
      </div>
      <div class="member-summary plain-text accent-text" :class="oppositeClass">
        {{ memberData.summary }}
      </div>
      <div class="member-description soft-text" :class="oppositeClass">
        <div v-html="memberData.description"></div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { ref, computed } from "vue";
import { common } from "@/helpers/common";

export default {
  name: 'TeamMember',
  props: {
    memberData: {
      type: Object,
      required: true
    },
    customClasses: {
      type: String,
      required: false,
      default: ''
    }
  },
  setup(props) {
    let subject = ref(null);
    let message = ref(null);
    let from = ref(null);

    //Methods

    //Computed
    const oppositeClass = computed(() => {
      if(props.customClasses.includes('scroll-fade-right')){
        return 'scroll-fade-left hidden';
      } else {
        return 'scroll-fade-right hidden';
      }
    });

    return {
      //Import
      common,
      //Data
      subject,
      message,
      from,
      oppositeClass
    }
  }
}
</script>

<style lang="scss">
.team-member {
  margin: 30px 0;
  height: auto;
  width: 85% !important;
  &.no-img {
    height: auto;
    justify-content: flex-start;
    flex-direction: row !important;
    .member-details {
      padding: 0px !important;
    }
  }
}
.member-details {
  width: 100%;
  .member-name {
    word-break: break-word;
  }
  .member-summary {
    transition-delay: 400ms;
    text-align: left;
    font-style: italic;
    margin: 5px 0 10px 0;
  }
  .member-description {
    transition-delay: 400ms;
    font-size: 20px;
    margin: 0 0 15px 0;
    div {
      margin: 10px 0 0 0;
    }
  }
}
.member-img {
  width: 50%;
  max-width: 500px;
  height: auto;
  max-height: 100%;
  position: relative;
  opacity: 1;
  float: left;
  padding-left: 0;
  padding-right: 30px;
  padding-bottom: 15px;
}
.team-member:nth-child(2n+2) {
  flex-direction: row-reverse;
  .member-img {
    padding-left: 30px;
    padding-bottom: 15px;
    padding-right: 0;
    float: right;
  }
}
</style>