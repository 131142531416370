<template>
  <Header />
  <router-view />
  <Toast v-for="t in toastQueue" :key="t.id" :options="t"></Toast>
</template>

<script>
import { computed, onMounted } from 'vue';
import { useStore } from "vuex";
import Toast from '@/components/Toast';
import Header from '@/components/Header';
import { useToast } from "@/composables/useToast";

export default {
  name: "SouthernOceanStudios",
  components: {
    Toast,
    Header
  },
  setup(){
    const store = useStore();
    const toast = useToast();

    //Computed
    const toastQueue = computed(() => store.state.toastQueue);
    const isMobile = computed(() => store.state.isMobile);

    //Methods
    const windowOpen = (url) => {
      window.open(url);
    };
    const checkIsMobile = (event) => {
      const e = event?.currentTarget || window;
      if(e?.innerWidth <= 699 && !isMobile.value){
        store.commit('setIsMobile', true);
      } else if(e?.innerWidth > 699 && isMobile.value){
        store.commit('setIsMobile', false);
      }
    };
    const setupEventListeners = () => {
      window.addEventListener('resize', (e) => {
        checkIsMobile(e);
      });
    };

    //Hooks
    onMounted(() => {
      checkIsMobile();
      setupEventListeners();
    });

    return {
      toast,
      toastQueue,
      windowOpen
    }
  }
}
</script>

<style lang="scss">
@import "@/styles/variables";

#app {
  background: $primary;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: auto;
  overflow-x: hidden;
  padding: 0px;
  width: 100%;
  height: 100%;
  padding: 0;
}
.header-icon {
  width: 25px;
}
</style>
