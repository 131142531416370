import { createRouter, createWebHashHistory } from 'vue-router';
import Admin from '../views/Admin.vue';
import Home from '../views/Home.vue';
import About from '../views/About.vue';
import Community from '../views/Community.vue';
import Events from '../views/Events.vue';

const routes = [
  {
    path: '/attherocks',
    name: 'Admin',
    component: Admin,
    beforeEnter: (to, from, next) => {
      next();
    },
  },
  {
    path: '/about',
    name: 'About',
    component: About,
    beforeEnter: (to, from, next) => {
      next();
    },
  },
  {
    path: '/community',
    name: 'Community',
    component: Community,
    beforeEnter: (to, from, next) => {
      next();
    },
  },
  {
    path: '/events',
    name: 'Events',
    component: Events,
    beforeEnter: (to, from, next) => {
      next();
    },
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    beforeEnter: (to, from, next) => {
      next();
    },
  }
]

const router = createRouter({
  mode: 'history',
  history: createWebHashHistory(),
  routes
})

export default router;
