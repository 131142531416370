<template>
  <div class="about-page">
    <div class="about column justify-center items-center">
      <div class="section column fade-in mb-lg">
        <h1>Who we are:</h1>
        <div style="font-size: 17px;">
          Southern Ocean Studios is a non-profit organization committed to cultivating a vibrant community through music, film and other artistic endeavors.
          The nonprofit offers benefit shows, filmed experiences such as monthly artist spotlights, and diverse art services including but not limited to a yearly film festival.
          Our mission is to unite individuals, celebrating our collective spirit and inspiring positive change through shared cultural expressions.
        </div>
      </div>
      <div class="section column fade-in-delayed">
        <h1>Our Mission:</h1>
        <div style="font-size: 17px;">
          Southern Ocean Studios will serve as a launchpad for up and coming artists in the Pacific Northwest by maximizing every ounce of their artistic potential;
          passing the torch to the next generation of creatives to carry on and improve upon Portland's rich artistic history.
        </div>
      </div>
    </div>
    <Team />
  </div>
</template>

<script>
import { provide, ref } from 'vue';
import Team from '@/components/Team';
export default {
  name: "About",
  components: {
    Team,
  },
  setup(){
    let hiddenElements = ref(null);

    const setupTransitions = () => {
      hiddenElements.value = document.querySelectorAll('.hidden');
      const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if(entry.isIntersecting) {
            entry.target.classList.remove('hidden');
          } else {
            entry.target.classList.add('hidden');
          }
        })
      });
      hiddenElements.value?.forEach((el) => observer.observe(el));
    };

    provide('setupTransitions', setupTransitions);

    return {
      setupTransitions
    }
  }
}
</script>

<style lang="scss">
@import "@/styles/variables";
.about-page {
  height: auto;
  overflow-y: auto;
  overflow-x: hidden;
}
.about {
  height: calc(100vh - 90px);
  width: 100%;
  .section {
    width: 85%;
  }
}
</style>
