<template>
  <div>
    <div class="community column items-center">
      <div class="section column fade-in mb-lg mt-xl">
        <CollapseEvent
          title="Thru the Laundry Room: A Monthly Concert Series"
        >
          <div class="section-sub-title">Origins and Evolution</div>
          <div class="section-details">Thru the Laundry Room began as an intimate house show, bringing together a small community of music enthusiasts in a cozy, informal setting. This grassroots concert series quickly gained a following for its unique atmosphere, where attendees could experience live music in a personal and relaxed environment. The name, inspired by the initial venue where guests had to walk through the house's laundry room to get to the music, became synonymous with authentic, up-close musical performances.</div>
          <div class="section-sub-title">A New Home: Spin Laundry Lounge</div>
          <div class="section-details">As the series grew in popularity, it outgrew its original space, leading to a new chapter at Spin Laundry Lounge. Located in the heart of Portland, Spin Laundry Lounge is not your typical laundromat. Known for its vibrant connection to the arts, it offers a perfect backdrop for Thru the Laundry Room to thrive. The laundromat's eclectic atmosphere, combined with its commitment to fostering community and creativity, makes it an ideal venue for this monthly gathering.</div>
          <div class="section-sub-title">The Experience</div>
          <div class="section-details">Every first Sunday of the month, Thru the Laundry Room transforms Spin Laundry Lounge into a dynamic performance space, where the hum of washers and dryers becomes part of the unique acoustic tapestry. Local and touring musicians alike take the stage, delivering a diverse array of genres and styles that reflect Portland's rich musical landscape. The unconventional setting breaks down barriers between performers and audience, creating an intimate and immersive experience for all.</div>
          <div class="section-sub-title">Community and Culture</div>
          <div class="section-details">Thru the Laundry Room is more than just a concert series; it's a celebration of community, creativity, and the unexpected intersections of everyday life. By bringing live music into a laundromat, it challenges traditional notions of where art can thrive and encourages a sense of wonder and discovery. Regular attendees appreciate the sense of belonging and the opportunity to connect with artists and fellow music lovers in a welcoming and unpretentious space. Join us at Spin Laundry Lounge each month for Thru the Laundry Room and be a part of this evolving musical journey, where the spin cycle and the song cycle intertwine in harmonious celebration.</div>
        </CollapseEvent>
        <CollapseEvent
          title="Family Dinner: An Annual Music Festival"
        >
          <div class="section-sub-title">A Southern Ocean Story</div>
          <div class="section-details">When Southern Ocean and its founders arrived in Portland, they were without a home and facing significant obstacles. Fortunately, a welcoming local family, the Kaysers, offered Israel Autumns and his friends a place to stay for a month. The first night they all shared a meal together, it was affectionately dubbed "Family Dinner." This tradition continued for the next year and remains a cherished practice among them to this day.</div>
          <div class="section-sub-title">What Does This Mean & Why Does It Matter?</div>
          <div class="section-details">Southern Ocean has teamed up with the local day shelter Rose Haven to support those without a home, aiming to assist individuals in similar or worse positions than they once faced upon arriving in this beautiful city. Their journey of finding community and support inspired them to give back, creating a ripple effect of kindness and solidarity.</div>
          <div class="section-sub-title">The Vision: A Diverse Musical Celebration</div>
          <div class="section-details">In honor of the kindness extended by the Kaysers, Southern Ocean holds an annual music festival called Family Dinner. This event not only pays homage to the spirit of community and togetherness but also serves as a platform for raising awareness and funds for the homeless.
          Entering its third year, the Family Dinner music festival has already seen immense success with its inaugural show selling out and its second gathering heavier hitters in the sponsorship world (like Topo Chico). The organizers have ambitious plans for the future, aiming to transform this event into one of the most celebrated and diverse music festivals in the country. In the next 5-10 years, "Family Dinner" has the potential to become a hallmark of cultural diversity and musical excellence, drawing crowds from across the nation and showcasing a wide array of genres and artists.</div>
          <div class="section-sub-title">Building a Legacy</div>
          <div class="section-details">Family Dinner is more than just a music festival; it's a celebration of community, resilience, and the power of coming together. By partnering with Rose Haven and other local organizations, Southern Ocean is committed to creating a lasting impact in Portland, helping those in need while fostering a sense of unity and belonging through music and shared experiences.</div>
        </CollapseEvent>
        <CollapseEvent
          title="Launching a New Annual Film Festival in Spring 2025"
        >
          <div class="section-details">We are currently working on the debut of the new Southern Ocean Studios film festival, set to premiere in the vibrant spring of 2025. This highly anticipated event will shine a spotlight on the dynamic world of short films and music videos, offering a fresh and exciting platform for emerging filmmakers and artists in the PNW to showcase their work.</div>
          <div class="section-details">The festival will be in partnership with the renowned Portland Center Stage. Located at The Armory in Downtown Portland, PCS has been a hub for artistic innovation and community engagement for years in the PDX. With a keen focus on short films and music videos, the festival aims to celebrate the artistry and creativity inherent in these formats, providing audiences with a diverse array of visual storytelling.</div>
          <div class="sub-section-title">Highlights</div>
          <div class="section-sub-title">Screenings:</div>
          <div class="section-details">Audiences can look forward to a carefully curated selection of short films and music videos, highlighting a range of genres, styles, and narratives. Each screening will offer a unique glimpse into the creative minds of both established and up-and-coming filmmakers.</div>
          <div class="section-sub-title">Q&A Sessions:</div>
          <div class="section-details">Post-screening Q&A sessions will provide an intimate setting for festival-goers to engage directly with filmmakers and artists. These interactive discussions will delve into the creative processes, challenges, and inspirations behind the featured works.</div>
          <div class="section-sub-title">Networking Opportunities:</div>
          <div class="section-details">The festival will host networking events designed to foster connections among filmmakers, artists, industry professionals, and enthusiasts. These gatherings will create a collaborative environment, encouraging the exchange of ideas and the building of professional relationships.</div>
          <div class="section-sub-title">Award Ceremony:</div>
          <div class="section-details">Celebrate excellence at the festival's prestigious award ceremony. Outstanding contributions in various categories will be honored, offering winners well deserved recognition. This event promises to be a highlight in the cultural calendar, showcasing the creativity and diversity of short-form visual storytelling. Join us in spring 2025 for an unforgettable celebration of film and music video artistry at Portland Center Stage.</div>
        </CollapseEvent>
      </div>
    </div>
    <Team />
  </div>
</template>

<script>
import { provide, ref } from 'vue';
import CollapseEvent from '@/components/CollapseEvent';
export default {
  name: "Community",
  components: {
    CollapseEvent
  },
  setup(){
    let hiddenElements = ref(null);

    const setupTransitions = () => {
      hiddenElements.value = document.querySelectorAll('.hidden');
      const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if(entry.isIntersecting) {
            entry.target.classList.remove('hidden');
          } else {
            entry.target.classList.add('hidden');
          }
        })
      });
      hiddenElements.value?.forEach((el) => observer.observe(el));
    };

    provide('setupTransitions', setupTransitions);

    return {
      setupTransitions
    }
  }
}
</script>

<style lang="scss">
@import "@/styles/variables";
.community {
  height: calc(100vh - 57px);
  overflow: auto;
  width: 100%;
  .section {
    width: 85%;
  }
}
</style>
