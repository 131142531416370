<template>
  <div id="content">
    <div class="logo-wrapper row justify-center items-center">
      <img class="sos-logo" src="/images/sos_logo_long.png" />
    </div>
    <!-- <div>
      <Carousel />
    </div> -->
    <ContactForm />
  </div>
  <Toast v-for="t in toastQueue" :key="t.id" :options="t"></Toast>
</template>

<script>
import { computed } from 'vue';
import Toast from '@/components/Toast';
import ContactForm from '@/components/ContactForm';
// import Carousel from '@/components/Carousel';
import { useToast } from "@/composables/useToast";
import { useStore } from "vuex";

export default {
  name: "Home",
  components: {
    // Carousel,
    ContactForm,
    Toast
  },
  setup(){
    const store = useStore();
    const toast = useToast();

    //Computed
    const toastQueue = computed(() => store.state.toastQueue);

    //Methods
    const getPosts = async () => {
      await store.dispatch('getPosts');
    }

    return {
      toast,
      toastQueue,
      getPosts,
    }
  }
}
</script>

<style lang="scss">
@import "@/styles/variables";
#content {
  height: auto;
  overflow: auto;
  padding: 0;
  width: 100%;
  margin: 0 auto;
  position: relative;
  .to-top-button {
    position: fixed;
    bottom: 10vh;
    right: 5%;
    font-size: 32px;
    cursor: pointer;
    border: 2px wavy;
    padding: 8px 20px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $default;
    z-index: 123;
  }
  .logo-wrapper {
    height: calc(100vh - 90px);
    .sos-logo {
      height: auto;
      width: 50%;
      display: inline-block;
      animation: fadeIn 1.5s;
      max-width: 580px;
    }
  }
}
</style>
