<template>
  <div class="toast-card" :class="[options.type, options ? 'active' : '']" v-if="options">
    <div class="toast-section" v-if="options.title">
      {{ options.title }}
    </div>
    <div class="toast-section">
      {{ options.message }}
    </div>
    <div class="toast-actions">
      <div></div>
    </div>
  </div>
</template>

<script>
import {onMounted} from "vue";

export default {
  name: 'Toast',
  props: {
    options: {
      require: true,
      type: Object,
    },
  },
  setup() {
    //Methods

    //Computed

    //Hooks

    onMounted(() => {
    });

    return {

    }
  },
}
</script>

<style lang="scss">
@import '@/styles/variables';

.toast-card {
  border: 2px dashed rgba(0,0,0,0.5);
  position: fixed;
  min-width: max-content;
  max-width: 95%;
  padding: 6px 23px;
  border-radius: 30px;
  left: 50%;
  transform: translate(-50%, -50%);
  bottom: 0px;
  opacity: 0;
  z-index: 2;
  font-size: 18px;
  transition: opacity ease-in-out 1s,bottom ease-in-out 1s;
  &.active {
    opacity: 1;
    bottom: 50%;
    transition: opacity ease-in-out 1s, bottom ease-in-out 1s;
  }
  &.positive {
    background: rgba($default-toast, 0.8);
    color: $default;
  }
  &.negative {
    background: $error;
  }
}


</style>
