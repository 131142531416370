let windowOpen = (link) => {
  window.open(link);
}
let goTo = (link) => {
  window.location = link
}

const common = {
  windowOpen,
  goTo
}

export { common };
