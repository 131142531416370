<template>
  <div>
    <div class="events column items-center mb-xl" v-if="visibleEvents.length">
      <div class="section full-width column fade-in">
        <div class="event justify-center mt-xl" :class="[ isMobile ? 'column' : 'row' ]" v-for="event in visibleEvents" :key="event.id">
          <img :src="event.media_url" />
          <div class="column ml-lg" v-if="event.title || event.description">
            <div class="event-title">{{ event.title }}</div>
            <div class="event-description">{{ event.description }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="row full-width items-center justify-center mt-xl" v-else>
      No current events
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex';
import { onMounted, computed } from 'vue';
export default {
  name: "Events",
  components: {
  },
  setup(){
    const store = useStore();

    //computed
    const isMobile = computed(() => store.state.isMobile);
    const events = computed(() => store.state.projects || []);
    const visibleEvents = computed(() => {
      let visibleEvents = [];
      const now = new Date();

      //Filter out expired and hidden events
      visibleEvents = events.value?.filter(e => {
        const projectExpiration = new Date(e.expiration_date);
        const isExpired = projectExpiration < now;
        return isExpired == false && e?.hidden == "false";
      });

      visibleEvents?.sort((a, b) => a.sort - b.sort);

      return visibleEvents;
    });

    //Lifecycle Hooks
    onMounted(() => {
      getProjects();
    });

    //Methods
    const getProjects = async () => {
      await store.dispatch('getProjects');
    }

    return {
      isMobile,
      visibleEvents,
      getProjects
    }
  }
}
</script>

<style lang="scss">
@import "@/styles/variables";
.events {
  min-height: calc(100vh - 55px);
  height: auto;
  overflow: auto;
  width: 100%;
  // .section .event:nth-of-type(2n+1) {
  //   display: flex;
  //   align-self: flex-end;
  //   flex-direction: column;
  //   align-items: flex-end;
  //   .event-title {
  //     right: 15px;
  //     position: relative;
  //   }
  // }
  .section {
    display: flex;
    align-self: center;
    flex-direction: column;
    align-items: center;
    .event-title {
      right: 15px;
      position: relative;
    }
  }
}
.event {
  position: relative;
  width: 100%;
  img {
    width: 38%;
  }
  .event-title {
    font-size: 42px;
    position: relative;
    padding-left: 15px;
    text-wrap: nowrap;
  }
}
</style>
