<template>
  <div class="community-section">
    <div class="section-title">
      {{title}}
      <div @click="toggleDetails" class="title-icon ml-md accent-text">{{ detailsVisible == false ? '+' : '-' }}</div>
    </div>
    <div class="section-details fade-in" v-show="detailsVisible">
      <slot>{{ details }}</slot>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
export default {
  props: {
    title: {
      type: String
    },
    details: {
      type: String
    },
    showDetailsOnLoad: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    let detailsVisible = ref(false);

    const toggleDetails = () => {
      detailsVisible.value = !detailsVisible.value;
    }

    onMounted(() => {
      if(props.showDetailsOnLoad){
        toggleDetails();
      }
    });

    return {
      detailsVisible,
      toggleDetails
    }
  },
}
</script>
<style lang="scss">
  .community-section {
    font-size: 17px;
  }
  .section-title {
    display: inline-block;
    font-size: 2em;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    unicode-bidi: isolate;
    .title-icon {
      cursor: pointer;
      display: inline-block;
      transition: all .5s ease-in-out;
    }
  }
  .section-sub-title {
    font-size: 1.1em;
    font-weight: bold;
    margin-bottom: 15px;
    margin-top: 10px;
    cursor: pointer;
  }
  .sub-section-title {
    font-size: 30px;
    margin: 20px 0 25px;
    font-weight: bold;
  }
</style>