<template>
  <div id="contact" class="contact-form-wrapper">
    <h1 class="mb-xl">Contact Us</h1>
    <form class="contact-form" method="POST" action="https://api.web3forms.com/submit">
      <input type="hidden" name="access_key" value="a95cefdf-d9d7-4d4c-b1c9-aae50d99997b" />
      <br />
      <input v-model="email" type="email" name="email" placeholder="Email" class="form-input email" required>
      <br />
      <input v-model="name" type="name" name="name" placeholder="Name" class="form-input name" required>
      <br />
      <textarea v-model="message" name="message" placeholder="Message" class="form-message" rows="10" required></textarea>
      <br />
      <div class="row full-width justify-end"><button type="submit" class="form-send">Send</button></div>
    </form>
  </div>
</template>
<script>
import { ref, onMounted } from "vue";

export default {
  name: 'Contact',
  setup() {
    let email = ref(null);
    let message = ref(null);
    let name = ref(null);

    //Methods
    const clearForm = () => {
      email.value = null;
      name.value = null;
      message.value = null;
    }

    //Life cycle hooks
    onMounted(() => {
      clearForm();
    });

    return {
      //Data
      email,
      message,
      name,
      //Methods
      clearForm
    }
  }
}
</script>
<style lang="scss">
/* write SCSS here */
.contact {
  height: auto;
  z-index: 1;
  position: relative;
  top: 0;
  width: 100%;
  display: block;
}
.contact-form-wrapper {
  height: 100vh;
  width: 100%;
  border-radius: 3px;
  padding-bottom: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.contact-form {
  display: inline-block;
  text-align: left;
  width: 55%;
  height: 50%;
  form {
    box-sizing: border-box;
    display: inline-block;
    width: 100%;
  }
}
</style>