<template>
  <div id="header" class="row my-md items-center full-width justify-between">
    <div class="row full-width items-center justify-between position-absolute" v-if="!isMobile">
      <div class="full-width row justify-center items-center self-center">
        <div class="nav-links">
          <router-link class="ml-md fade-in" to="/">home</router-link>
          <router-link class="ml-md fade-in" to="/about">about us</router-link>
          <router-link class="ml-md fade-in" to="/community">our community</router-link>
          <router-link class="ml-md fade-in" to="/events">events</router-link>
        </div>
      </div>
    </div>
    <template v-else>
      <Dropdown>
        <img class="southern-ocean-logo-mobile" src="/images/so_shape_w.png" />
      </Dropdown>
    </template>
    <div class="mr-md" style="height: 23px; z-index: 123;">
      <img class="cursor-pointer header-icon ml-md" src="/images/instagram_icon_white.png" alt="southern-ocean-studios-instagram-icon" @click="common.windowOpen('https://www.instagram.com/southernoceanstudios/')"/>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed, inject } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { common } from "@/helpers/common";
import Dropdown from './Dropdown.vue';
// import HeadLoader from './HeadLoader.vue';
export default {
  name: "Header",
  setup() {
    const store = useStore();
    const router = useRouter();
    const cartVisible = inject('cartVisible');
    let subMenuOpen = ref(false);
    let headerLogo = ref('headerLogo');
    let headerWrapper = ref('headerWrapper');

    //Methods
    function goTo(route) {
      router.push({path: route});
      closeCart();
    }
    function toggleSubMenu() {
      subMenuOpen.value = !subMenuOpen.value;
    }
    function toggleCart() {
      cartVisible.value = !cartVisible.value;
    }
    function closeCart() {
      cartVisible.value = false;
    }

    //Computed Properties
    const customerCart = computed(() => {
      let itemsInCart = 0;
      if(store.state.customerCart.length > 0){
        store.state.customerCart.forEach(item => {
          itemsInCart += item.quantity;
        })
      }
      return itemsInCart;
    });
    const isMobile = computed(() => store.state.isMobile);

    //Lifecycle Hooks
    onMounted(() => {

    })

    return {
      common,
      subMenuOpen,
      customerCart,
      headerLogo,
      headerWrapper,
      isMobile,
      cartVisible,
      toggleSubMenu,
      toggleCart,
      closeCart,
      goTo,
    }
  },
  components: {
    Dropdown,
    // HeadLoader
  },
}
</script>

<style lang="scss">
@import "@/styles/variables";

#header-container {
  width: 100%;
  position: fixed;
  z-index: 12;
  background: black;
  .nav-bar {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 97%;
    height: 50px;
    margin: 0px auto;
    position: relative;
    z-index: 2;
  }
  .header-socials {
    .header-icon {
      height: auto;
      width: 55%;
      z-index: 3;
    }
  }
  .header-drawer-wrapper {
    position: absolute;
  }
  .southern-ocean-logo {
    max-width: 30px;
    position: relative;
    cursor: pointer;
    animation: fadeDown 1s;
    z-index: 3;
  }
  .header-socials-drawer {
    position: relative;
    opacity: 0;
    left: 0;
    transition: all ease-in-out .5s;
    z-index: 2;
    &.open-drawer {
      opacity: 1;
      left: 17px !important;
      transition: all ease-in-out .5s;
    }
    .header-icon {
      height: auto;
      width: 50%;
    }
  }
  .southern-ocean-logo-mobile {
    max-width: 30px;
    animation: fadeIn 1s;
    position: relative;
    top: -1px;
    right: 2px;
  }
}
.bar-info {
  width: 100%;
  display: flex;
  align-self: center;
  justify-content: center;
  align-items: center;
}
.nav-links {
  display: flex;
  flex-direction: row;
  color: $secondary-text;
  font-size: 12px;
  font-family: 'body-font';
  cursor: pointer;
  text-transform: uppercase;
  animation: fadeIn 2s;
  div:hover {
    color: $primary;
  }
}
</style>